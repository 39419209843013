<script lang="ts">
  import { afterUpdate } from "svelte";
  import { flip } from "svelte/animate";
  import { slide } from "svelte/transition";
  import { dragHandle, dragHandleZone, type DndEvent } from "svelte-dnd-action";

  import {
    animations,
    deleteWithJwt,
    dialogTypes,
    filterMenu,
    getNewPositions,
    isClient,
    MealEntityType,
    patchWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";

  import {
    alertStore,
    journalMealsStore,
    dialogData,
    selectedIdsStore,
    mealPlanStore,
    recipesStore,
    user,
    mealPlansStore,
    activeMealsStore,
  } from "stores";

  import {
    Badge,
    ButtonComponent,
    CheckboxComponent,
    ExpandCollapseComponent,
    More,
    Svg,
    TemplateTagComponent,
  } from "ui";

  import { IngredientComponent } from "diet";
  import Macros from "./Macros.svelte";
  import type {
    Ingredient,
    MenuItem,
    PartialItemMap,
    Recipe,
  } from "interfaces";

  let recipe: Recipe;
  let isSelectable = false;
  let isSelected = false;
  let isExpanded = false;
  let menuItems: Array<MenuItem> = [];

  const newDefaultLink = "/logo.png";

  const onImportIngredients = (): void => {
    $dialogData.data = { recipe };
    $dialogData.type = dialogTypes.IMPORT_INGREDIENTS;
  };

  const select = (): void => {
    const { id } = recipe;

    if (isSelected) {
      selectedIdsStore.add(id);
    } else {
      selectedIdsStore.remove(id);
    }
  };

  const editRecipeDialog = (): void => {
    $dialogData.data = { recipe };
    $dialogData.type = dialogTypes.CREATE_EDIT_RECIPE;
  };

  const copyRecipe = async (): Promise<void> => {
    const { id, mealId, isDefault } = recipe;

    const partialRecipeMap: PartialItemMap<Recipe> = {
      [id]: {
        isTemplate: 0,
      },
    };

    const { error, data } = await postWithJwt(
      `${serverlessRoutes.RECIPE}/copy`,
      { partialRecipeMap }
    );

    if (error && !data) {
      alertStore.show(translate("ERROR_COPYING_RECIPE"), "error");
      return console.error(error);
    }

    const { recipes } = data;

    if (mealId) {
      mealPlanStore.addMealRecipes(mealId, recipes);
      journalMealsStore.addMealRecipes(mealId, recipes);
    } else if (isDefault) {
      recipesStore.add(recipes);
    }

    alertStore.show(translate("SUCCESSFULLY_COPIED_RECIPE"));
  };

  const deleteRecipe = async (): Promise<void> => {
    const { id, mealId, isDefault } = recipe;

    const { data, error } = await deleteWithJwt(serverlessRoutes.RECIPE, {
      ids: [id],
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_DELETING_RECIPE"), "error");
      return console.error(error);
    }

    const { ids } = data;

    if (mealId) {
      mealPlanStore.removeMealRecipes(mealId, ids);
      journalMealsStore.removeMealRecipes(mealId, ids);
    } else if (isDefault) {
      recipesStore.remove(ids);
    }

    alertStore.show(translate("SUCCESSFULLY_DELETED_RECIPE"));
  };

  const deleteRecipeDialog = (): void => {
    $dialogData.data = {
      executeFunction: deleteRecipe,
      title: recipe.name,
    };

    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  // refactor
  const saveToJournal = async (): Promise<void> => {
    const trainerMeal = $mealPlanStore?.meals?.find(
      ({ id }): boolean => id === recipe.mealId
    );

    const clientMeal = $activeMealsStore.items.find(
      ({ id }): boolean => id === recipe.mealId
    );

    const meal = trainerMeal || clientMeal;

    if (!meal || meal.entityType !== MealEntityType.NORMAL) {
      return;
    }

    if (!$mealPlansStore.items.length) {
      await mealPlansStore.fetchData();
    }

    const mealPlanId = $mealPlansStore.items[0].id;
    const date = new Date().toISOString();

    const journalMeal = $journalMealsStore.items.find(
      ({ name, finishedAt }): boolean => {
        return (
          finishedAt?.slice(0, 10) === date.slice(0, 10) && name === meal.name
        );
      }
    );

    if (!journalMeal) {
      const { error, data } = await postWithJwt(serverlessRoutes.MEAL, {
        partialMeal: {
          name: meal.name,
          description: meal.description,
          foodType: meal.foodType,
          position: meal.position,
          entityType: meal.entityType,
          showMacros: meal.showMacros,
          mealPlanId: mealPlanId,
          commentableId: meal.commentableId,
          createdAt: date,
          updatedAt: date,
          calories: meal.calories,
          protein: meal.protein,
          carbs: meal.carbs,
          fats: meal.fats,
          finishedAt: date,
          fileableId: meal.fileableId,
        },
      });
      const response = await postWithJwt(`${serverlessRoutes.RECIPE}/copy`, {
        partialRecipeMap: {
          [recipe.id]: {
            isDefault: 0,
            isTemplate: 0,
            mealId: data.meal.id,
            trainerId: null,
          },
        },
      });
    } else {
      const response = await postWithJwt(`${serverlessRoutes.RECIPE}/copy`, {
        partialRecipeMap: {
          [recipe.id]: {
            isDefault: 0,
            isTemplate: 0,
            mealId: journalMeal.id,
            trainerId: null,
          },
        },
      });
    }

    await journalMealsStore.fetchData();
    journalMealsStore.generateMealMap();

    alertStore.show(translate("SUCCESSFULLY_SAVED_TO_JOURNAL"));
  };

  const saveToDatabase = async (): Promise<void> => {
    const partialRecipeMap: Partial<Recipe> = {
      [recipe.id]: {
        mealId: null,
        isTemplate: 0,
        isDefault: 1,
      },
    };

    const { error, data } = await postWithJwt(
      `${serverlessRoutes.RECIPE}/copy`,
      { partialRecipeMap }
    );

    if (error && !data) {
      return console.error(error);
    }

    alertStore.show(`
      ${translate("RECIPE")}
      ${translate("SAVED_TO_DATABASE").toLowerCase()}
    `);
  };

  const templateDialog = (): void => {
    $dialogData.data = {
      type: "recipe",
      executeFunction: copyRecipe,
    };

    $dialogData.type = dialogTypes.TEMPLATE;
  };

  const onExpand = async (): Promise<void> => {
    // if (isSelectable) {
    //   isSelected = !isSelected;
    //   return select();
    // }

    isExpanded = !isExpanded;

    const { id, mealId, isDefault, ingredients } = recipe;

    if (isExpanded && !ingredients) {
      if (mealId) {
        const trainerMeal = $mealPlanStore?.meals?.find(
          ({ id }) => id === mealId
        );
        const journalMeal = $journalMealsStore.items.find(
          ({ id }) => id === mealId
        );
        const activeMeal = $activeMealsStore.items.find(
          ({ id }) => id === mealId
        );

        if (trainerMeal) {
          await mealPlanStore.fetchRecipeIngredients(mealId, id);
        } else if (journalMeal) {
          await journalMealsStore.fetchRecipeIngredients(mealId, id);
        } else if (activeMeal) {
          await activeMealsStore.fetchRecipeIngredients(mealId, id);
        }
        // await journalMealsStore.fetchRecipeIngredients(mealId, id);
        // await mealPlanStore.fetchRecipeIngredients(mealId, id);

        // if (isClient($user)) {
        // await activeMealsStore.fetchRecipeIngredients(mealId, id);
        // }
      } else if (isDefault) {
        await recipesStore.fetchIngredients(id);
      }
    }
  };

  const ingredientsDndConsider = async (
    event: CustomEvent<DndEvent<Ingredient>>
  ): Promise<void> => {
    const { id, isDefault, mealId } = recipe;
    const { items } = event.detail;

    if (isDefault) {
      recipesStore.setIngredients(id, items);
    } else if (mealId) {
      mealPlanStore.setRecipeIngredients(mealId, id, items);
    }
  };

  const ingredientsDndFinalize = async (
    event: CustomEvent<DndEvent<Ingredient>>
  ): Promise<void> => {
    const { id, isDefault, mealId } = recipe;
    const partialIngredientMap = getNewPositions(event.detail.items);

    if (!partialIngredientMap) {
      return;
    }

    const { error, data } = await patchWithJwt(serverlessRoutes.INGREDIENT, {
      partialIngredientMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_CHANGING_ORDER"), "error");
      return console.error(error);
    }

    const { ingredients } = data;

    if (isDefault) {
      recipesStore.replaceIngredients(id, ingredients);
    } else if (mealId) {
      mealPlanStore.replaceRecipeIngredients(mealId, id, ingredients);
    }

    alertStore.show(translate("SUCCESSFULLY_CHANGED_ORDER"));
  };

  const unfilteredMenuItems: MenuItem[] = [
    {
      icon: "save",
      title: "SAVE_TO_DATABASE",
      executeFunction: saveToDatabase,
    },
    {
      icon: "save",
      title: "SAVE_TO_JOURNAL",
      executeFunction: saveToJournal,
    },
    {
      icon: "edit",
      title: "EDIT",
      executeFunction: editRecipeDialog,
    },
    {
      icon: "copy",
      title: "CREATE_COPY",
      executeFunction: copyRecipe,
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction: deleteRecipeDialog,
    },
    {
      icon: "chart-bar",
      title: "DETAILS",
      executeFunction: templateDialog,
    },
  ];

  const generateMenu = (): void => {
    const { isTemplate, isDefault, mealId } = recipe;

    if (isClient($user)) {
      if (mealId) {
        if (window.location.href.includes("/meal-plan")) {
          menuItems = filterMenu(unfilteredMenuItems, ["SAVE_TO_JOURNAL"]);
        } else if (window.location.href.includes("/journal")) {
          menuItems = filterMenu(unfilteredMenuItems, ["DELETE"]);
        }
      }
    } else {
      if (isTemplate) {
        menuItems = filterMenu(unfilteredMenuItems, ["CREATE_COPY", "DETAILS"]);
      } else if (isDefault) {
        menuItems = filterMenu(unfilteredMenuItems, [
          "EDIT",
          "CREATE_COPY",
          "DELETE",
        ]);
      } else if (mealId) {
        if (window.location.href.includes("/profile") && $mealPlanStore) {
          menuItems = filterMenu(unfilteredMenuItems, [
            "SAVE_TO_DATABASE",
            "SAVE_TO_JOURNAL",
            "EDIT",
            "CREATE_COPY",
            "DELETE",
          ]);
        } else {
          menuItems = filterMenu(unfilteredMenuItems, [
            "SAVE_TO_DATABASE",
            "EDIT",
            "CREATE_COPY",
            "DELETE",
          ]);
        }
      }
    }
  };

  afterUpdate(generateMenu);

  const getImageUrl = (): string => {
    if (recipe.fileHash) {
      if (recipe.fileHash.includes("watch")) {
        return recipe.fileHash.replace("watch", "thumbnails/thumbnail.jpg");
      } else if (recipe.fileHash.includes("imagedelivery")) {
        return recipe.fileHash;
      } else {
        return recipe.imageUrl;
      }
    } else {
      return "logo.png";
    }
  };

  export { recipe, isSelectable };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="
    relative
    flex
    flex-col
    leading-tight
    bg-white
    border
    border-slate-200
    rounded-md
    cursor-pointer
    transition-[border-color]
    duration-[333ms]
    linear
    dark:bg-zinc-800
    dark:border-zinc-600
  "
  class:isSelected
>
  {#if recipe.isTemplate}
    <div class="z-50">
      <TemplateTagComponent />
    </div>
  {/if}

  <!-- Wrapper -->
  <div class="flex gap-4">
    <!-- Slika-->
    <div
      class="h-[128px] w-[96px] relative flex items-center justify-center rounded-l-md bg-black overflow-hidden"
      on:click={(event) => {
        if (
          recipe?.fileHash &&
          recipe?.fileHash?.includes("watch") &&
          !isSelectable
        ) {
          event.stopPropagation();
          $dialogData.data = { src: recipe?.fileHash };
          $dialogData.type = dialogTypes.VIDEO_ZOOM_V2;
        }
      }}
    >
      <img
        class="max-h-[256px] max-w-[192px]"
        src={getImageUrl()}
        alt={recipe.name}
      />
      <!-- src={recipe.fileHash ? recipe.fileHash.replace("watch", "thumbnails/thumbnail.jpg") : newDefaultLink} -->

      {#if recipe?.fileHash?.includes("watch")}
        <div class="play-overlay"></div>
      {/if}
    </div>

    <div
      class="text-xs leading-none py-2 flex flex-col items-center justify-center grow gap-2"
      on:click={() => {
        onExpand();
      }}
    >
      <div id="recipe-name" class="text-center leading-tight max-w-44">
        {recipe.name}
      </div>
      <div>
        <span class="text-amber-400">{Math.round(recipe.calories || 0)}</span>
        kcal
      </div>
      <div class="w-full">
        <Macros
          isBarVisible
          carbs={recipe.carbs || 0}
          protein={recipe.protein || 0}
          fats={recipe.fats || 0}
          calories={recipe.calories || 0}
        />
      </div>
    </div>

    <!-- Dugmici -->
    <div class="p-2 pl-0 flex flex-col justify-center gap-4">
      {#if isSelectable}
        <CheckboxComponent bind:value={isSelected} on:change={select} />
        <ExpandCollapseComponent {isExpanded} on:click={onExpand} />
      {:else}
        <More {menuItems} />
        {#if !recipe.isDefault && !isClient($user)}
          <div use:dragHandle>
            <Svg name="drag" size={16} />
          </div>
        {/if}
        <ExpandCollapseComponent {isExpanded} on:click={onExpand} />
      {/if}
    </div>
  </div>

  {#if isExpanded}
    <div
      class="p-4 pt-0 flex flex-col gap-4"
      in:slide={animations.slide}
      out:slide={animations.slide}
    >
      <div></div>

      <div>
        <div class="font-semibold">{translate("FOOD_TYPE")}:</div>
        {#if recipe.foodType && recipe.foodType.length}
          <div class="flex flex-wrap gap-2">
            {#each recipe.foodType.split(",") as name}
              <Badge>{name}</Badge>
            {/each}
          </div>
        {:else}
          <div class="text-justify text-xs">{translate("NO_TYPE")}</div>
        {/if}
      </div>

      <div>
        <div class="font-semibold">{translate("DESCRIPTION")}:</div>
        <div class="text-justify text-xs">
          {recipe.description || translate("NO_DESCRIPTION")}
        </div>
      </div>

      <div>
        <div class="font-semibold">{translate("INGREDIENTS")}:</div>
        {#if recipe.ingredients?.length}
          <div
            class="flex flex-col gap-4"
            use:dragHandleZone={{
              items: recipe.ingredients,
              flipDurationMs: 0,
              dropTargetStyle: {},
              dropFromOthersDisabled: true,
              type: "recipeIngredients",
            }}
            on:consider={ingredientsDndConsider}
            on:finalize={ingredientsDndFinalize}
          >
            {#each recipe.ingredients as ingredient (ingredient.id)}
              <div class="outline-none" animate:flip={animations.flip}>
                <IngredientComponent
                  {ingredient}
                  isRecipeTemplate={recipe.isTemplate && recipe.isTemplate === 1
                    ? true
                    : false}
                />
              </div>
            {/each}
          </div>
        {:else}
          <div class="text-justify text-xs">{translate("NO_INGREDIENTS")}</div>
        {/if}
      </div>

      {#if !isClient($user) && !recipe.isTemplate}
        <div class="flex justify-center">
          <ButtonComponent isOutline on:click={onImportIngredients}>
            {translate("IMPORT_INGREDIENTS")}
          </ButtonComponent>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .isSelected {
    border-color: rgb(var(--primary));
  }

  .play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-style: solid;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-width: 10px 0px 10px 20px;
    border-color: transparent transparent transparent #fff;
  }
</style>
