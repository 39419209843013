<script lang="ts">
  import { user, dialogData, showAlert, theme, currentClient, trainerExercisesStore, alertStore, selectedIdsStore } from "stores";
  import { dialogTypes, serverlessRoutes } from "../../lib/constants";
  import {
    api,
    deleteWithJwt,
    getWithJwt,
    postWithJwt,
    putWithJwt,
    translate,
  } from "lib";
  import {
    cantDoExercises,
    trainerClientPrograms,
    trainerClientWorkouts,
    trainerPrograms,
    trainerWorkouts,
  } from "../../stores/trainerStores";
  import More from "../UI/More.svelte";
  import Svg from "../UI/Svg.svelte";
  import ExerciseDetails from "./ExerciseDetails.svelte";
  import type { MenuItem } from "../../interfaces/MenuItem";
  import { createEventDispatcher, onMount } from "svelte";
  import { loadedWorkouts } from "../../stores/loadedWorkoutsStore";
  import { isClient } from "../../lib/roles";
  import { dragHandle } from "svelte-dnd-action";
  import { CheckboxComponent } from "ui";
  import { stats } from "../../stores/finishWorkoutStats";

  let exercise: any;
  let overlayElement: HTMLDivElement;
  let exerciseInWorkoutElement: HTMLDivElement;

  const dispatch = createEventDispatcher();

  $: isProfileRoute = window.location.href.includes("/profile");

  export let isInExpansionPanel = false;
  export let isSelectMode = false;
  export let isTemplate = false;
  export let programId = 0;
  export let isSupersetBeingEdited = false;
  export let type: "" | "CANT_DO_EXERCISES" = "";

  const deleteExerciseFromProgramState = () => {
    $loadedWorkouts[exercise.workoutId].exercises = $loadedWorkouts[
      exercise.workoutId
    ].exercises.filter(({ id }) => id !== exercise.id);
  };

  const onSelectExercise = (): void => {
    if ($selectedIdsStore.includes(exercise.id)) {
      $selectedIdsStore = $selectedIdsStore.filter(
        (id) => id !== exercise.id
      );
      if (type === "CANT_DO_EXERCISES") {
        dispatch("deleteCantDoExercise", exercise.id);
      }
    } else {
      $selectedIdsStore = [...$selectedIdsStore, exercise.id];
      if (type === "CANT_DO_EXERCISES") {
        dispatch("addCantDoExercise", {
          id: exercise.id,
          workoutId: exercise.workoutId,
        });
      }
    }
  };


  const finishExerciseDialog = (): void => {
    $dialogData.data = {exercise};
    $dialogData.type = dialogTypes.FINISH_EXERCISE_V2;
  };

  const exerciseHistoryDialog = (): void => {
    $dialogData.data = {exercise};
    $dialogData.type = dialogTypes.EXERCISE_HISTORY_V2;
  };

  const editExerciseDialog = (): void => {
    if (
      exercise.entityType === 0 ||
      exercise.entityType === null ||
      exercise.entityType === undefined
    ) {
      if (isInExpansionPanel) {
        $dialogData.data = {
          programId,
          exercise,
          workoutId: exercise.workoutId,
          executeFunction(res: any): void {
            exercise = res;
          },
        };
        $dialogData.type = dialogTypes.CREATE_EDIT_EXERCISE;
      } else {
        $dialogData.data = { exercise };
        $dialogData.type = dialogTypes.CREATE_EDIT_EXERCISE;
      }
    } else if (exercise.entityType === 1) {
      $dialogData.data = {
        programId,
        workoutId: exercise.workoutId,
        exerciseId: exercise.id,
        exercise: {
          name: exercise.name,
          description: exercise.description,
        },
        executeFunction(res: any) {
          exercise = res;
        },
      };
      $dialogData.type = dialogTypes.CREATE_EDIT_HEADER_WORKOUT;
    }
  };

  const copyExercise = async (): Promise<void> => {
    const {id} = exercise;

    const partialExerciseMap = {
      [id]: {
        isTemplate: 0
      }
    };

    const {error, data} = await postWithJwt(
      `${serverlessRoutes.EXERCISE}/copy/v2`,
      {partialExerciseMap}
    );

    if (error && !data) {
      alertStore.show(translate("ERROR_COPYING_EXERCISE"), "error");
      return console.error(error);
    }

    const {exercises} = data;

    if (exercise.default) {
      trainerExercisesStore.add(exercises);
    }

    alertStore.show(translate("SUCCESSFULLY_COPIED_EXERCISE"));
  };

  const deleteExercise = async (): Promise<void> => {
    const {id} = exercise;

    const {data, error} = await deleteWithJwt(serverlessRoutes.EXERCISE, {
      ids: [id]
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_DELETING_EXERCISE"), "error");
      return console.error(error);
    }

    trainerExercisesStore.remove(data.ids);

    if (exercise.workoutId) {
      $trainerPrograms.forEach((p) => {
        if (!p?.workouts) {
          return;
        }
        const w = p.workouts.find(({id}) => id === exercise.workoutId);
        if (w?.exercises) {
          w.exercises = w.exercises.filter((e) => e.id !== id);
        }
      });
      $trainerClientPrograms.forEach((p) => {
        if (!p?.workouts) {
          return;
        }
        const w = p.workouts.find(({id}) => id === exercise.workoutId);
        if (w?.exercises) {
          w.exercises = w.exercises.filter((e) => e.id !== id);
        }
      });

      const w = $trainerWorkouts.find(({id}) => id === exercise.workoutId);
      if (w?.exercises) {
        w.exercises = w.exercises.filter((e) => e.id !== id);
      }

      const w2 = $loadedWorkouts[exercise.workoutId];
      if (w2?.exercises) {
        w2.exercises = w2.exercises.filter((e) => e.id !== id);
      }

      $trainerPrograms = $trainerPrograms;
      $trainerClientPrograms = $trainerClientPrograms;
      $trainerWorkouts = $trainerWorkouts;
      $loadedWorkouts = $loadedWorkouts;
    }

    alertStore.show(translate("SUCCESSFULLY_DELETED_EXERCISE"));
  };

  const deleteExerciseDialog = (): void => {
    $dialogData.data = {
      title: exercise.name,
      executeFunction: deleteExercise
    };

    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };


  let menuItems: Array<MenuItem> = [{
    title: "ENTER_RESULTS",
    icon: "results",
    executeFunction: finishExerciseDialog
  }, {
    title: "EXERCISE_HISTORY",
    icon: "history",
    executeFunction: exerciseHistoryDialog
  }, {
    title: "EDIT",
    icon: "edit",
    executeFunction: editExerciseDialog
  }, {
    title: "CREATE_COPY",
    icon: "copy",
    executeFunction: copyExercise
  }, {
    title: "DELETE",
    icon: "delete",
    executeFunction: deleteExerciseDialog
  }, {
      title: "CANT_DO_EXERCISE",
      icon: "tag",
      async executeFunction(): Promise<void> {
        const clientId = window.location.href.split("/")[5];

        try {
          const res = await postWithJwt(
            `${serverlessRoutes.EXERCISE}/cant-do-exercises`,
            {
              parentExerciseId: exercise.parentExerciseId,
              userId: $currentClient.id || clientId,
            }
          );

          if (res.error === "Item with exerciseId and userId already exists.") {
            $showAlert.color = "red-500";
            $showAlert.message = translate("ERROR_ALREADY_ADDED_EXERCISE");
          } else {
            $cantDoExercises.exercises = [
              ...$cantDoExercises.exercises,
              res.data.exercise,
            ];
            $cantDoExercises.exerciseIds = [
              ...$cantDoExercises.exerciseIds,
              exercise.parentExerciseId,
            ];

            if (!$cantDoExercises.workoutIds.includes(exercise.workoutId)) {
              $cantDoExercises.workoutIds = [
                ...$cantDoExercises.workoutIds,
                exercise.workoutId,
              ];
            }

            trimMenu();

            $showAlert.color = "black";
            $showAlert.message = translate("SUCCESS_ADDING_EXERCISE");
          }
        } catch (error) {
          $showAlert.color = "red-500";
          $showAlert.message = translate("ERROR_ADDING_EXERCISE");
          console.error(error);
        }
      },
    },
    {
      title: "CAN_DO_EXERCISE",
      icon: "tag",
      async executeFunction(): Promise<void> {
        const { EXERCISE, WORKOUT } = serverlessRoutes;
        const clientId = window.location.href.split("/")[5].split("?")[0];
        const id = exercise.parentExerciseId;

        try {
          await deleteWithJwt(`${EXERCISE}/cant-do-exercises`, {
            parentExerciseId: id,
            userId: clientId,
          });

          if ($currentClient.id) {
            // journal
            const workoutIds = `workoutIds=%5B${$trainerClientWorkouts.data.map((w) => w.id).join(",")}%5D`;
            const userId = `userId=${clientId}`;
            const response = await getWithJwt(
              `${WORKOUT}/cant-do-exercises?${workoutIds}&${userId}`
            );

            $cantDoExercises.workoutIds = response.data.cantDo;
          } else {
            // plan
            const programId = parseInt(window.location.href.split("/")[8]);
            const program = $trainerClientPrograms.find(
              (program) => program.id === programId
            );
            const workoutIds = `workoutIds=%5B${program.workouts.map((w) => w.id).join(",")}%5D`;
            const userId = `userId=${clientId}`;
            const response = await getWithJwt(
              `${WORKOUT}/cant-do-exercises?${workoutIds}&${userId}`
            );

            $cantDoExercises.workoutIds = response.data.cantDo;
          }

          $cantDoExercises.exercises = $cantDoExercises.exercises.filter(
            (exercise) => exercise.id !== id
          );
          $cantDoExercises.exerciseIds = $cantDoExercises.exerciseIds.filter(
            (eid) => eid !== id
          );

          trimMenu();

          $showAlert.color = "black";
          $showAlert.message = translate("SUCCESS_DELETING_EXERCISE");
        } catch (error) {
          $showAlert.color = "red-500";
          $showAlert.message = translate("ERROR_DELETING_EXERCISE");
          console.error(error);
        }
      },
    },
    {
      title: "CONNECT_WITH_PARENT_EXERCISE",
      icon: "warning",
      executeFunction(): void {
        $dialogData.data = {
          exercise,
          executeFunction(data: any): void {
            exercise.parentExerciseId = data.exercise.parentExerciseId;
            trimMenu();
          },
        };
        $dialogData.type = dialogTypes.CONNECT_WITH_PARENT_EXERCISE;
      },
    },
  ];

  let filteredMenuItems: Array<MenuItem> = [];

  const finalMenuIs = (items: Array<string>): void => {
    filteredMenuItems = menuItems.filter((menu) => items.includes(menu.title));
  };

  const openDialog = (): void => {
    $dialogData.data = exercise;
    $dialogData.type = dialogTypes.EXERCISE_PREVIEW;
  };

  dialogData.subscribe((dialogChanges): void => {
    if (
      dialogChanges.data &&
      dialogChanges.data.updatedExerciseId &&
      dialogChanges.data.updatedExerciseId === exercise.id
    ) {
      exercise.finishedAt = new Date();
    }
  });

  const onDeleteCantDoExercise = (): void => {
    dispatch("deleteCantDoExercise", exercise.id);
  };

  const onUpdateExerciseDetails = async (event: CustomEvent<any>) => {
    const { details } = event.detail;
    const { id } = exercise;

    try {
      const url = `${api}/exercise/${id}`;
      const response = await putWithJwt(url, { details, id });

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("EXERCISE").toLowerCase()}
      `;

      if (exercise.workoutId) {
        trainerExercisesStore.replace([response]);

        const index =
          $loadedWorkouts[exercise.workoutId].exercises.indexOf(exercise);

        $loadedWorkouts[exercise.workoutId].exercises.splice(
          index,
          1,
          response
        );
        $loadedWorkouts = $loadedWorkouts;
      } else if (exercise.supersetId) {
        Object.keys($loadedWorkouts).forEach((key): void => {
          const ss = $loadedWorkouts[key].exercises.find(
            ({ id }) => id === exercise.supersetId
          );

          if (!ss) {
            return;
          }

          const ssIndex = $loadedWorkouts[key].exercises.indexOf(ss);
          const sse = ss.supersetExercises.find(({ id }) => id === exercise.id);

          if (!sse) {
            return;
          }

          const sseIndex = ss.supersetExercises.indexOf(sse);

          $loadedWorkouts[key].exercises[ssIndex].supersetExercises =
            $loadedWorkouts[key].exercises[ssIndex].supersetExercises.with(
              sseIndex,
              response
            );
        });
        $loadedWorkouts = $loadedWorkouts;
      }

      if (overlayElement) overlayElement.style.display = "none";
    } catch (error) {
      console.error(error);
    }
  };

  const onToggleOverlay = (event: CustomEvent<boolean>): void => {
    if (!overlayElement) return;
    overlayElement.style.display = event.detail ? "initial" : "none";
  };

  const trimMenu = (): void => {
    // ===== TRENER =====
    // KOD KLIJENTA U PROGRAM: [Izmeni, Obrisi, Ne moze vezbu]
    // KOD KLIJENTA U PROGRAM U SUPERSET: [Ne moze vezbu]
    // KOD KLIJENTA U DNEVNIK: [Unesi rezultate, Istorija vezbe, Ne moze vezbu]
    // KOD KLIJENTA U DNEVNIK U SUPERSET: [Istorija vezbe, Ne moze vezbu]
    // KOD TRENERA: [Izmeni, Obrisi]
    // KOD TRENERA U TRENING: [Izmeni Obrisi]
    // KOD TRENERA U TRENING U SUPERSET: Nema
    // KOD TRENERA U PROGRAM U TRENING: [Izmeni, Obrisi]
    // KOD TRENERA U PROGRAM U TRENING U SUPERSET: Nema

    // ===== KLIJENT =====
    // PLAN TRENINGA: [Unesi rezultate, Istorija vezbe]
    // PLAN TRENINGA U SUPERSET: [Istorija vezbe]
    // DNEVNIK TRENINGA: [Unesi rezultate, Istorija vezbe]
    // DNEVNIK TRENINGA U SUPERSET: [Istorija vezbe]
    let connectWithParentExercise: string;

    if (isProfileRoute) {
      if (exercise.parentExerciseId) {
        if ($cantDoExercises.exerciseIds.includes(exercise.parentExerciseId)) {
          connectWithParentExercise = "CAN_DO_EXERCISE";
        } else {
          connectWithParentExercise = "CANT_DO_EXERCISE";
        }
      } else {
        connectWithParentExercise = "CONNECT_WITH_PARENT_EXERCISE";
      }
    } else {
      if (exercise.parentExerciseId) {
        connectWithParentExercise = "";
      } else {
        connectWithParentExercise = "CONNECT_WITH_PARENT_EXERCISE";
      }
    }

    // const CONNECT_WITH_PARENT_EXERCISE = exercise.parentExerciseId
    //   ? "CANT_DO_EXERCISE"
    //   : "CONNECT_WITH_PARENT_EXERCISE";

    // const CONNECT_WITH_PARENT_EXERCISE2 = exercise.parentExerciseId
    //   ? ""
    //   : "CONNECT_WITH_PARENT_EXERCISE";

    if (isClient($user)) {
      if (exercise.supersetId) {
        return finalMenuIs(["EXERCISE_HISTORY"]);
      } else {
        return finalMenuIs(["ENTER_RESULTS", "EXERCISE_HISTORY"]);
      }
    } else {
        console.log(exercise, "IS DEFAULT EXERCISE!");
      if (exercise.isTemplate) {
        return finalMenuIs(["CREATE_COPY"]);
      } else if (exercise.default) {
        return finalMenuIs(["EDIT", "CREATE_COPY", "DELETE"]);
      } else if (isProfileRoute) {
        if (window.location.href.includes("type=diary")) {
          if (exercise.supersetId) {
            return finalMenuIs(["EXERCISE_HISTORY", connectWithParentExercise]);
          } else {
            return finalMenuIs([
              "ENTER_RESULTS",
              "EXERCISE_HISTORY",
              connectWithParentExercise,
            ]);
          }
        } else {
          if (exercise.supersetId) {
            return finalMenuIs([connectWithParentExercise]);
          } else {
            return finalMenuIs(["EDIT", "DELETE", connectWithParentExercise]);
          }
        }
      } else {
        if (window.location.href.includes("/training/exercises")) {
          return finalMenuIs(["EDIT", "DELETE"]);
        } else {
          if (exercise.supersetId) {
            return finalMenuIs([connectWithParentExercise]);
          } else {
            return finalMenuIs(["EDIT", "DELETE", connectWithParentExercise]);
          }
        }
      }
    }
  };

  onMount((): void => {
    trimMenu();

    if (overlayElement) overlayElement.style.display = "none";

    const { href } = window.location;

    if (isClient($user)) {
      if (href.includes("scrollToExerciseComments")) {
        const exerciseId = parseInt(
          href.split("?")[1].split("&")[1].split("=")[1]
        );
        let scrollToElement: HTMLElement | null;

        if (exercise.id === exerciseId) {
          scrollToElement = document.getElementById(`scroll-${exercise.id}`);
        } else if (exercise.supersetId === exerciseId) {
          scrollToElement = document.getElementById(
            `scroll-${exercise.supersetId}`
          );
        }

        setTimeout(
          (): void => scrollToElement?.scrollIntoView({ behavior: "smooth" }),
          2000
        );
      }
    } else {
      if (href.includes("scrollToExerciseComments")) {
        let exerciseId: number;
        let scrollToElement: HTMLElement | null;

        if (href.includes("programId")) {
          exerciseId = parseInt(href.split("&")[4].split("=")[1]);
        } else {
          exerciseId = parseInt(href.split("&")[1].split("=")[1]);
        }

        if (exercise.id === exerciseId) {
          scrollToElement = document.getElementById(`scroll-${exercise.id}`);
        } else if (exercise.supersetId === exerciseId) {
          scrollToElement = document.getElementById(
            `scroll-${exercise.supersetId}`
          );
        }

        setTimeout(
          (): void => scrollToElement?.scrollIntoView({ behavior: "smooth" }),
          2000
        );
      }
    }
  });

  export { exercise };
</script>

{#if exercise.entityType === 0 || exercise.entityType === null || exercise.entityType === undefined}
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="
      relative
      flex
      border
      border-slate-200
      rounded-md
      bg-white
      dark:border-zinc-600
      dark:bg-zinc-800
    "
    bind:this={exerciseInWorkoutElement}
  >
    <div
      class="fixed top-0 right-0 w-full h-full z-1000"
      bind:this={overlayElement}
    ></div>

    <div class="relative grow-0 shrink-0 basis-28" on:click={openDialog}>
      <img
        class="rounded-l-md"
        src="https://img.youtube.com/vi/{exercise.youtubeId}/hqdefault.jpg"
        alt="Exercise Video"
      />
      <div class="play-overlay"></div>
    </div>

    {#if exercise.isTemplate}
      <div class="tag">
        <div class="tag__clip bg-primary-500 dark:bg-primary-700"></div>
        <div class="tag__icon">
          <Svg myClass="mr-2" name="star" size={12} customColor="bg-white" />
        </div>
      </div>
    {/if}

    <div class="p-2 flex flex-col justify-between grow" on:click={openDialog}>
      <div
        class="text-center text-xs custom-overflow leading-tight dark:text-slate-100"
      >
        {exercise.name}
        {#if exercise.type}
          - {exercise.type}
        {/if}
      </div>

      {#if type !== "CANT_DO_EXERCISES"}
        <ExerciseDetails
          type={exercise.type}
          sets={exercise.details?.sets}
          reps={exercise.details?.reps}
          rest={exercise.details?.rest}
          time={exercise.details?.time}
          distance={exercise.details?.distance}
          isSupersetExercise={exercise.supersetId}
          on:updateExerciseDetails={onUpdateExerciseDetails}
          on:toggleOverlay={onToggleOverlay}
        />
      {/if}
    </div>

    <div
      class="p-2 flex flex-col"
      class:justify-center={type === "CANT_DO_EXERCISES"}
      class:justify-between={type !== "CANT_DO_EXERCISES"}
    >
      {#if isSelectMode}
        {#key $selectedIdsStore}
          <CheckboxComponent
            value={$selectedIdsStore.includes(exercise.id)}
            on:change={onSelectExercise}
          />
        {/key}
        {#if isSupersetBeingEdited}
          <div use:dragHandle>
            <Svg name="drag" size={16} />
          </div>
        {/if}
      {:else if type === "CANT_DO_EXERCISES"}
        <Svg
          name="delete"
          size={24}
          customColor="bg-red-500"
          on:click={onDeleteCantDoExercise}
        />
      {:else}
        <More menuItems={filteredMenuItems} />

        {#if !exercise.supersetId && !window.location.href.includes("type=diary") && !window.location.href.includes("training/exercises") && !isClient($user)}
          <div use:dragHandle>
            <Svg name="drag" size={16} />
          </div>
        {/if}

        {#if exercise.finishedAt}
          <Svg name="check" size={13} customColor="bg-green-500" />
        {:else if $stats.find((s) => s.exerciseId === exercise.id)?.stats[0]?.weight}
          <Svg name="check" size={13} customColor="bg-blue-500" />
        {/if}

        {#if exercise.parentExerciseId === null && !isClient($user)}
          <Svg name="warning" size={16} customColor="bg-yellow-500" />
        {:else if exercise.parentExerciseId && $cantDoExercises.exerciseIds.includes(exercise.parentExerciseId) && window.location.href.includes("/profile")}
          <Svg name="warning" size={16} customColor="bg-red-500" />
        {/if}
      {/if}
    </div>
  </div>
{:else if exercise.entityType === 1}
  <div
    class="p-2 flex bg-gray-100 dark:bg-zinc-700 border rounded-md shadow m-2"
  >
    <div class="flex flex-col gap-4 grow">
      <div class="w-full flex items-center justify-between">
        <div>{exercise.name}</div>
      </div>
      {#if exercise.description}
        <div>
          <div class="font-bold">{translate("DESCRIPTION")}:</div>
          <div class="indent-4">{exercise.description}</div>
        </div>
      {/if}
    </div>
    <div class="flex flex-col items-center justify-between">
      {#if exercise.entityType !== 1}
        <More menuItems={filteredMenuItems} />
        <Svg name="drag" size={16} />
      {/if}
    </div>
  </div>
{/if}

<style>
  .play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-style: solid;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-width: 10px 0px 10px 20px;
    border-color: transparent transparent transparent #fff;
  }
  .custom-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tag {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 32px;
    width: 32px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }
  .tag__icon {
    position: absolute;
    top: 4px;
    left: 4px;
  }
  .tag__clip {
    height: 32px;
    width: 32px;
    clip-path: polygon(0px 0px, 32px 0px, 0px 32px);
    /* background-color: #6875f5; */
  }
</style>
