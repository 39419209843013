<script lang="ts">
  import { deleteWithJwt, serverlessRoutes, translate } from "lib";
  import {
    signCloudflareImage,
    uploadAndSignImageCloudflare,
    uploadImageCloudflare,
  } from "../../lib/cloudflare/uploadImageCloudflare";
  import {
    getVideoStatus,
    isVideoReady,
    uploadVideoToCloudflareStreams,
  } from "../../lib/cloudflare/uploadVideoCloudflare";
  import { showAlert } from "stores";
  import { ButtonComponent, MiniSpinner } from "ui";
  import { onMount } from "svelte";

  let imageUrl: string;
  let videoUrl = "";
  let value: string | null;
  export let unsignedValue = "";
  let requireSignedURLs = true;
  let shouldDelete = false;
  let isVideoAllowed = false;
  let label = "";
  export let isPrivate = false;

  let imageInputElem: HTMLInputElement;
  let imageElem: HTMLImageElement;
  let videoElement: HTMLIFrameElement;

  let isFileSelected = false;
  let isUploading = false;
  let isDeleting = false;
  let isVideo = false;
  let isImage = false;

  const onSetFile = async (event: any): Promise<void> => {
    const target = event.target as HTMLInputElement;

    if (target.files) {
      const [file] = target.files;

      isFileSelected = true;
      isUploading = true;
      isVideo = file.type.includes("video");
      isImage = file.type.includes("image");

      if (isVideo) {
        console.log({ requireSignedURLs });
        const uploadResult = await uploadVideoToCloudflareStreams(
          file,
          requireSignedURLs
        );

        if (!uploadResult.videoUrl) {
          $showAlert.message = "Otpremanje videa nije uspelo";
          isUploading = false;
          return;
        }

        const interval = setInterval(async (): Promise<void> => {
          const status = await getVideoStatus(uploadResult.videoUrl);

          if (status) {
            videoElement.src = status.preview;
            value = status.preview;
            shouldDelete = true;

            clearInterval(interval);
          }
        }, 5_000);
      } else if (isImage) {
        let uploadResult;

        if (isPrivate) {
          uploadResult = await uploadAndSignImageCloudflare(file);
        } else {
          uploadResult = await uploadImageCloudflare(file, requireSignedURLs);
        }

        if (!uploadResult.imageUrl) {
          $showAlert.message = "Otpremanje slike nije uspelo";
          isUploading = false;
          return;
        }

        imageElem.src = uploadResult.imageUrl;
        value = uploadResult.imageUrl;
        unsignedValue = uploadResult.fileHash;
        shouldDelete = true;
      }
    }
  };

  export const onDeleteFile = async (): Promise<void> => {
    if (!value) {
      return;
    }

    isDeleting = true;

    if (isImage) {
      const imageId = value.split("/")[4];

      await deleteWithJwt(`${serverlessRoutes.FILE}/image`, { imageId });
    } else if (isVideo) {
      const videoId = value.split("/")[3];

      await deleteWithJwt(`${serverlessRoutes.FILE}/video`, { videoId });
    }

    value = "";
    isFileSelected = false;
    isDeleting = false;
    imageUrl = "";
    videoUrl = "";
    isImage = false;
    isVideo = false;
  };

  const onAddFile = (): void => {
    imageInputElem.click();
  };

  const mountInputElement = (element: HTMLInputElement): void => {
    imageInputElem = element;
  };

  const mountImageElement = (element: HTMLImageElement): void => {
    imageElem = element;
  };

  const mountVideoElement = (element: HTMLIFrameElement): void => {
    videoElement = element;
  };

  onMount(() => {
    if (imageUrl) {
      isImage = true;
    } else if (videoUrl) {
      isVideo = true;
    }
  });

  export {
    label,
    imageUrl,
    videoUrl,
    value,
    requireSignedURLs,
    shouldDelete,
    isUploading,
    isVideoAllowed,
  };
</script>

<div>
  <div class="h-4 ml-1 flex items-center leading-none text-xs font-medium">
    {label ? label : translate("FILE")}:
  </div>

  <div
    class="p-2 flex flex-col items-center justify-center gap-2 ring-1 ring-inset ring-gray-300 dark:ring-slate-600 dark:bg-zinc-700 rounded-md"
  >
    {#if isUploading}
      <MiniSpinner />
    {/if}

    <div class:hidden={isUploading}>
      {#if isImage}
        <img
          id="input-img"
          class="cursor-pointer"
          src={imageUrl}
          alt="Uploaded file"
          on:load={() => {
            isUploading = false;
          }}
          use:mountImageElement
        />
      {:else if isVideo}
        <iframe
          title="Video player"
          src={videoUrl}
          allow="accelerometer; gyroscope; encrypted-media;"
          allowfullscreen={true}
          on:load={() => {
            isUploading = false;
          }}
          use:mountVideoElement
        ></iframe>
      {:else if imageUrl}
        <img
          class="cursor-pointer"
          src={imageUrl || "logo.png"}
          alt="Default"
        />
      {:else if videoUrl}
        <iframe
          title="Video player"
          src={videoUrl}
          allow="accelerometer; gyroscope; encrypted-media;"
          allowfullscreen={true}
        ></iframe>
      {:else}
        <img class="cursor-pointer" src={"logo.png"} alt="Default" />
      {/if}
    </div>

    <input
      class="hidden"
      type="file"
      accept=".png,.jpeg,.jpg,.webp,.svg{isVideoAllowed
        ? ',.mp4,.mkv,.mov,.avi,.flv,.ts,.ps,.mxf,.lxf,.gxf,.3gp,.webm,.mpg,.qt'
        : ''}"
      on:change={onSetFile}
      use:mountInputElement
    />

    <div class="flex flex-col items-center justify-center gap-2">
      {#if !imageUrl && !videoUrl && !isFileSelected}
        <div class="text-xxs leading-none">
          {translate("DEFAULT_IMAGE_LOADED_AUTOMATICALLY")}
        </div>
        <!-- {translate("CLICK_ON_IMAGE_TO_CHANGE")} -->
        <ButtonComponent on:click={onAddFile}>
          {translate("ADD")}
        </ButtonComponent>
      {:else if !isUploading}
        <ButtonComponent
          customColor="bg-red-500"
          isLoading={isDeleting}
          on:click={onDeleteFile}
        >
          {translate("DELETE")}
        </ButtonComponent>
      {/if}
    </div>
  </div>
</div>
