<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { showAlert } from "stores";

  let timeout: NodeJS.Timeout;

  const onCloseAlert = (): void => {
    clearTimeout(timeout);
    $showAlert.message = "";
  };

  onMount((): void => {
    timeout = setTimeout((): void => {
      $showAlert.message = "";
    }, 3000);
  });
</script>

<div
  in:fade={{ duration: 333 }}
  class="alert flex flex-col items-center justify-center text-{$showAlert.color}"
  on:click={onCloseAlert}
>
  {$showAlert.message}
</div>

<style>
  .alert {
    position: fixed;
    z-index: 1000;
    bottom: 52px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 92%;
    height: 64px;
    background-color: #fff;
    border: 1px solid silver;
    border-radius: 0.375rem;
  }
</style>
